import { useEffect } from 'react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Menu from '../src/components/Menu';
import AboutChainZoom from './components/AboutChainZoom';
import BlogComponent from './components/Blog';
import Footer from './components/Footer';
import Partners from './components/Partners';
import Porfolio from './components/Porfolio';
import Services from './components/Services';
import './styles/_style.scss';

function App() {
  useEffect(() => {
    const mybutton = document.getElementById("btnBackToTop");
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }
  }, [])

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div>
      <button onClick={goToTop} id="btnBackToTop" title="Go to top">
      </button>
      <div className='wrap-intro' id="home">
        <div className="container">
            <Menu/>
            <div className='wrap-intro-content'>
              <div className='intro-content'>
                <h3 className="title is-3">BLOCKCHAIN SECURITY SMART CONTRACT AUDIT|</h3>
                <h6 className="subtitle is-6">Established in 2021, ChainZoom aimed at using techniques to perform security audits smart contracts on public blockchains.</h6>
                <button className="button button-contact-us"><a  href="#footer">Contact us</a></button>
              </div>
            </div>
        </div>
      </div>
      <BlogComponent />
      <AboutChainZoom />
      <Partners />
      <Services />
      <Porfolio />
      <Footer />
    </div>
  );
}

export default App;
