import { logo, MenuIcon, Close } from '../assets';
import React, { useState } from 'react';
import Scrollspy from 'react-scrollspy'

const Menu = () => {

  const [isActive, setIsActive] = useState(false);

  return (
    <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
      <div className='container'>
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src={logo} width="112" height="28" alt="logo"/>
          </a>
          <a role="button" onClick={() => setIsActive(!isActive)} className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <div>
              <img src={MenuIcon} alt="menu" />
            </div>
          </a>
        </div>
        <div id="navbarBasicExample" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
          <div className="navbar-close" onClick={() => setIsActive(false)}>
            <img src={Close} alt="close"/>
          </div>
          <div className="navbar-start">
          <Scrollspy
            items={ ['home', 'blog', 'about-us', 'partners', 'services', 'porfolio', 'achievements'] }
            currentClassName="is-current"
            className='wrap-item-menu'
          >
            <a href='#home' className="navbar-item">
              Home
            </a>

            <a href='#blog' className="navbar-item">
              News & Insights
            </a>

            <a href='#about-us' className="navbar-item">
              About us
            </a>

            <a href="#partners" className="navbar-item">
              Partners
            </a>
            <a href='#services' className="navbar-item">
              Services
            </a>

            <a href='#porfolio' className="navbar-item">
              Portfolio
            </a>
            <a href='#achievements' className="navbar-item">
              Achievements
            </a>
          </Scrollspy>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <a href='#footer' className="button is-primary is-outlined">
                  <strong>Contact Us</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
