import {
  auditing, reviewCode, smartContract
} from '../assets';
const Services = () => {
  return(
    <div className='wrap-services' id="services">
        <div className='container'>
        <div className='services-title'>
            <h5 className="title is-5  title-common">OUR SERVICES</h5>
        </div>
        <div className='wrap-card'>
          <div className="card">
            <div className="card-image">
                <div className='img'>
                  <img src={smartContract} alt="Placeholder"/>
                </div>
            </div>
            <div className="card-content">
              <div className="media">
                <div className="media-content">
                  <p className="title is-5">Smart Contract Audits Services</p>
                </div>
              </div>

              <div className="content subtitle">
                A comprehensive security solutions of your smart contract and blockchain code to identify vulnerabilities,
                weakness and recommend a custom-made solution to fix them.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-image">
                <div className='img'>
                  <img src={reviewCode} alt="Placeholder"/>
                </div>
            </div>
            <div className="card-content">
              <div className="media">
                <div className="media-content">
                  <p className="title is-5">Backend Code Review</p>
                </div>
              </div>

              <div className="content subtitle">
              A comprehensive security solutions of your smart contract and blockchain code to identify vulnerabilities,
              weakness and recommend a custom-made solution to fix them.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-image">
                <div className='img'>
                  <img src={auditing} alt="Placeholder"/>
                </div>
            </div>
            <div className="card-content">
              <div className="media">
                <div className="media-content">
                  <p className="title is-5">Blockchain Software Code Review & Auditing</p>
                </div>
              </div>

              <div className="content subtitle">
                We supports code review and security auditing services for custom blockchain implementations in a number of languages.
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  );
}
export default Services;