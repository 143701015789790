import {
  blog
} from '../assets';

const Blog = () => {
  return (
    <div className='wrap-blog'  >
        <div className='container' id="blog">
          <div className='blog-content-top'>
            <div className='blog-content-left'>
              <img src={blog} alt="blog"/>
            </div>
            <div className='blog-content-right'>
              <h5 className="title is-5 title-blog">EXPLORE NEWS & INSIGHTS</h5>
              <p className="subtitle is-5 content-blog">Our blog got updated news about blockchain everyday</p>
              <div>
                <a href='https://medium.com/@chainzoom.sec' target='_blank' className="button button-blog" rel="noreferrer">
                  Go to our blog
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Blog;