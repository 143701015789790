import {
  Dopikai, ekoios, Satom, sotaHolding, Sotanext, sotatekImg, Vcc
} from '../assets';

const Partners = () => {
  return(
    <div className='wrap-partners' id="partners">
        <div className='container'>
          <div className='partners-title'>
            <h5 className="title is-5  title-common">OUR PARTNERS</h5>
          </div>
          <div className='wrap-content'>
              <div className='parters-top'>
                <div className='top'>
                  <img src={sotaHolding} alt="sotatek"/>
                </div>
                <div className='top'>
                  <img src={sotatekImg} alt="sotatek"/>
                </div>
                <div className='top'>
                  <img src={ekoios} alt="sotatek"/>
                </div >
                <div className='bottom'>
                  <img src={Sotanext} alt="sotatek"/>
                </div>
                <div className='bottom'>
                  <img src={Vcc} alt="sotatek"/>
                </div>
                <div className='bottom'>
                  <img src={Dopikai} alt="sotatek"/>
                </div>
                <div className='bottom'>
                  <img src={Satom} alt="sotatek"/>
                </div>
              </div>
              <div className='partners-bottom'>
              </div>
          </div>
        </div>
      </div>
  );
}

export default Partners;