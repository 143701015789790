import Slider from "react-slick";
import {

  Metaverse,
  Socialgood,
  jigStack,
  sotatekFinance,
  amanpuri,
  paceArt,
  redKite,
  Lemonade,
  banance,
  opGame,
  BlockStack,
  cosmos,
  oasis,
  hack,
  chainLink,
  hyperHack,
  honeyComp,

} from '../assets';

const Porfolio = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 1500,
    arrows: false,
    dotsClass: 'porfolio-dots',
    centerMode: true,
    className: "center",
    centerPadding: "0",
    customPaging: i=> <div></div>,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  const settingAchievements ={
    ...settings,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  const porfolioList = [
    { id: 0, img: Metaverse, title: 'METAVERSE STARTER', link: 'https://metaversestarter.io/'},
    { id: 1, img: Socialgood, title: 'SOCIALGOOD', link: 'https://socialgood.inc/'},
    { id: 2, img: Lemonade, title: 'LEMONADE DEFI', link: 'https://www.lemonadefi.com/'},
    { id: 3, img: jigStack, title: 'JIGSTACK', link: 'https://jigstack.org/'},
    { id: 4, img: Metaverse, title: 'SOTA FINANCE', link: 'https://sota.finance/'},
    { id: 5, img: sotatekFinance, title: 'ZOIDPAY WALLET', link: 'https://zoidpay.com/'},
    { id: 6, img: amanpuri, title: 'AMANPURI EXCHANGE', link: 'https://twitter.com/amanpuriex'},
    { id: 7, img: paceArt, title: 'PACE ART', link: 'https://app.pace.art/'},
    { id: 8, img: redKite, title: 'REDKITE', link: 'https://redkite.polkafoundry.com/'},
  ];

  const achievementsList = [
    { id : 0,
      img: banance,
      title: 'Binance Hackathon',
      subtitle: '2021-02-03',
      content: 'MoW - Third Prize and Honorable Mentions Prize',
      link: 'https://gitcoin.co/hackathon/binance/projects/4334/mow'
    },
    { id: 1,
      img: banance,
      title: 'Binance Hackathon',
      subtitle: '2021-02-03',
      content: 'Lendingswap - Third Prize and Honorable Mentions Prize',
      link: 'https://gitcoin.co/hackathon/binance/projects/4343/lendingswap'
    },
    { id: 2,
      img: hack,
      title: 'Hack the Horizon',
      subtitle: '2021-01-25',
      content: 'First Prize',
      link: 'https://gitcoin.co/hackathon/hack-the-horizon/projects/4184/Lending-Swap'
    },
    { id: 3,
      img: hyperHack,
      title: 'Icon Hyperhack Hackathon',
      subtitle: '2020-10-01',
      content: 'Grand Prize',
      link: 'https://devpost.com/software/bonsai-exchange'
    },
    { id: 4,
      img: hack,
      title: 'Defi & Cross-Border Finance (Harmony-Cosmos-Cross)',
      subtitle: '2020-10-22',
      content: 'First Place',
      link: 'https://devpost.com/software/harmony-cosmos-cross'
    },
    { id: 5,
      img: hack,
      title: 'Defi & Cross-Border Finance (CryptoPet)',
      subtitle: '2020-10-22',
      content: '2 Runner-up prizes',
      link: 'https://devpost.com/software/cryptopet'
    },
    { id: 6,
      img: chainLink,
      title: 'Chainlink Hackathon',
      subtitle: '2020-10-03',
      content: 'Runner up',
      link: 'https://devpost.com/software/goldedstarswap'
    },
    { id: 7,
      img: oasis,
      title: 'Oasis Protocol Hackathon',
      subtitle: '2020-12-16',
      content: 'Silver Prize',
      link: 'https://gitcoin.co/hackathon/oasis/projects/3719/secure-docs'
    },
    { id:8,
      img: BlockStack,
      title: 'Blockstack - Clarity Hackathon',
      subtitle: '2020-08-14',
      content: 'Second Runner Up Prize',
      link: 'https://devpost.com/software/clarity-composable-token'
    },
    { id:9,
      img: opGame,
      title: 'OP Game Hackathon',
      subtitle: '2020-08-09',
      content: 'First Place',
      link: 'https://gitcoin.co/hackathon/unitize/projects/1162/typing-racer'
    },
    { id: 10,
      img: cosmos,
      title: 'Cosmos & Band Protocol - Cross-Chain Hackathon',
      subtitle: '2020-08-14',
      content: 'Honorable Mentions Prize',
      link: 'https://gitcoin.co/hackathon/cosmos/projects/635/$Sema'
    },
    { id: 11,
      img: honeyComp,
      title: 'Honeycomb - Smart Contract Hackathon',
      subtitle: '2019-12',
      content: 'World Weather Online Challenge Prize',
      link: 'https://devpost.com/software/evi'
    },
  ];

  return(
    <div className='wrap-porfolio'>
      <div className='container'  id="porfolio">
        <div className='porfolio-title'>
          <h5 className="title is-5 title-common">PORFOLIO</h5>
        </div>
        <div className='wrap-card-porfolio'>
          <Slider {...settings}>
            {porfolioList.map(item => (
              <div key={item.id}>
                <div className="card">
                  <div className="card-image">
                      <a href={item.link} target='_blank' rel="noreferrer">
                        <div className='img'>
                          <img src={item.img} alt="Placeholder"/>
                        </div>
                      </a>
                  </div>
                  <div className="card-content">
                    <div className="media">
                      <div className="media-content">
                        <a href={item.link} target='_blank' rel="noreferrer">
                          <p className="title is-5">{item.title}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div id="achievements">
          <div className='achievements-title'>
            <h5 className="title is-5 title-common">ACHIEVEMENTS</h5>
          </div>
        </div>
        <div className='wrap-card-achievements'>
          <Slider {...settingAchievements} id="achievements-slider">
            {achievementsList.map(item => (
              <div key={item.id}>
                <div className="card">
                  <div className="card-image">
                      <a href={item.link} target='_blank' rel="noreferrer">
                        <div className='img'>
                            <img src={item.img} alt="Placeholder"/>
                        </div>
                      </a>
                  </div>
                  <div className="card-content">
                    <div className="media">
                      <div className="media-content">
                        <a href={item.link} target='_blank' rel="noreferrer">
                          <p className="title is-5">{item.title}</p>
                        </a>
                        <p className="subtitle is-6">{item.subtitle}</p>
                      </div>
                    </div>
                    <div className="content subtitle">
                      {item.content}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Porfolio;
