import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import {
  dangerIcon, Facebook, Linkedin, logo, Twitter
} from '../assets';
import { instanceAxios } from '../services';
import { useState } from 'react'


const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Footer = () => {
  const schema = yup
  .object()
  .shape({
    email: yup.string().required("Email is required").email('Email is not correct. Please check and retype'),
    name: yup.string().required("Name is required"),
    message: yup.string().required("Message is required"),
  })
  .required();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      message: '',
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const params = { ...data};
    try {
      const res = await instanceAxios.post('/contacts', params);
      if (res.status === 201) {
        toast('Contact request submitted successfully!')
        setValue('email', '');
        setValue('name', '');
        setValue('message', '');
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const [emailSubscribe, setEmailSubscribe] = useState('');
  const [errorEmailSubscribe, setErrorEmailSubscribe] = useState('')

  const onSubscribe = async() => {
    if (!emailSubscribe) {
      setErrorEmailSubscribe('Email is required');
      return;
    }
    if(!regex.test(emailSubscribe)) {
      setErrorEmailSubscribe('Email is not correct. Please check and retype')
      return;
    }
    setErrorEmailSubscribe('');
    const params = {
      email: emailSubscribe
    }
    try {
      const res = await instanceAxios.post('/newsletters/subscribers', params);
      if (res.status === 201) {
        toast('Subscribe successfully!')
        setEmailSubscribe('');
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <div className='footer' id="footer">
      <ToastContainer />
      <div className='container is-flex'>
        <div className='footer-left'>
          <div className='logo'>
            <img src={logo} alt="logo"/>
          </div>
          <div className='intro subtitle'>
            ChainZoom is a blockchain security consulting company focus on smart contract auditing and blockchain software developing
          </div>
          <div className='sign-up'>
            <label >Newsletter sign up</label>
            <div className='form-signup'>
              <input
                value={emailSubscribe}
                className="input"
                placeholder='Your email here'
                onChange={(e) => setEmailSubscribe(e.target.value)}
              />
              <div>
                <button className="button button-subcribe" onClick={onSubscribe}>Subscribe</button>
              </div>
            </div>
            {errorEmailSubscribe &&
              <span style={{ color: '#FF4842', fontSize: '0.85rem', marginTop: 10, display: 'inline-block'}}>
                <img style={{ marginRight: 10 }} src={dangerIcon} alt="danger" />
                {errorEmailSubscribe}
              </span>
            }
          </div>
          <div className='social-icon'>
            <div>
              <a href='https://www.facebook.com/chainzoomsec' target='_blank' rel="noreferrer">
                <img src={Facebook} alt="icon" />
              </a>
            </div>
            <div>
              <a href='https://twitter.com/ChainZoom' target='_blank' rel="noreferrer">
                <img src={Twitter} alt="icon" />
              </a>
            </div>
            <div>
              <a href="https://www.linkedin.com/company/chainzoom" target='_blank' rel="noreferrer">
                <img src={Linkedin} alt="icon" />
              </a>
            </div>

          </div>
        </div>
        <div className='footer-right'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className='subtitle'>
            Get in touch
          </label>
          <input  {...register("name")} className='input' placeholder='Your full name'/>
          {errors?.name?.message &&
            <span style={{ color: '#FF4842', fontSize: '0.85rem' }}>
              <img style={{ marginRight: 10 }} src={dangerIcon} alt="danger" />
              {errors.name.message}
            </span>
          }
          <input {...register("email")}  className='input' placeholder='Your email'/>

          {errors?.email?.message &&
            <span style={{ color: '#FF4842', fontSize: '0.85rem' }}>
              <img style={{ marginRight: 10 }} src={dangerIcon} alt="danger" />
              {errors.email.message}
            </span>
          }
          <textarea {...register("message")}  className="textarea" placeholder='Your message' />
          {errors?.message?.message &&
            <span style={{ color: '#FF4842', fontSize: '0.85rem', marginBottom: 10, display: 'inline-block'}}>
              <img style={{ marginRight: 10 }} src={dangerIcon} alt="danger" />
              {errors?.message?.message}
            </span>
          }
          <div className='button-submit'>
            <button type='submit' className='button is-primary is-outlined'>Submit</button>
          </div>
        </form>
        </div>
      </div>
    </div>
  );
}
export default Footer;
