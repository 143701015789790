import {
  aboutChainzoom, ballDeco
} from '../assets';
const AboutChainZoom = () => {
  return (
    <div className='wrap-about'>
        <div className='img-deco'>
          <img src={ballDeco} alt="deco" />
        </div>
        <div className='container'  id="about-us">
          <div className='blog-content-bottom'>
            <div className='blog-content-right'>
              <h5 className="title is-5 title-blog title-about">ABOUT&nbsp;<h5 className='title is-5'>CHAINZOOM </h5></h5>
              <p className="subtitle is-5 content-blog">
                ChainZoom is a blockchain security firm established in 2021.
                Our goal is to make the blockchain ecosystem as secure as possible for everyone.
                With a team of experienced smart contract auditors & developers with deep expertise building and securing complex decentralized networks and applications,
                We offer a comprehensive security solution customized to individual projects.
              </p> 
            </div>
            <div className='blog-content-left'>
              <img src={aboutChainzoom} alt="blog"/>
            </div>
          </div>
        </div>
      </div>
  );
}

export default AboutChainZoom;